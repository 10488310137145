import { useState } from 'react';

import BoxList from '../BoxList';
import Footer from '../Footer';
import Header from '../Header';
import Seasons from '../Seasons';

import './App.css';

const App = () => {
  const [DMs, setDMs] = useState(['test']);

  const buildDM = (val) => {
    setDMs(val);
  };

  return (
    <Seasons>
      <Header buildDM={buildDM} />
      <BoxList boxes={DMs} />
      <Footer />
    </Seasons>
  );
};

export default App;
