const PrintButton = () => {
  const print = () => {
    window.print();
  };

  return (
    <a className="print-doc" onClick={print}>
      <button className="season-button">Распечатать</button>
    </a>
  );
};

export default PrintButton;
