import WaveBackground from '../WaveBackground';
import Title from '../Title';
import Form from '../Form';
import PrintButton from '../PrintButton';

const Header = ({ buildDM }) => {
  return (
    <section>
      <WaveBackground />
      <Title />
      <Form buildDM={buildDM} />
      <PrintButton />
      <div className="padding-div notforprint"></div>
    </section>
  );
};

export default Header;
