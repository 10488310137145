import { useState } from 'react';

const Form = ({ buildDM }) => {
  const [formVal, setFormVal] = useState('');
  const submitForm = (e) => {
    e.preventDefault();
    let resArr = formVal.split('\n');
    setFormVal('');
    buildDM(resArr);
  };

  return (
    <form onSubmit={submitForm}>
      <p>
        <textarea
          name="manydatamatrix"
          value={formVal}
          onChange={(e) => {
            setFormVal(e.target.value);
          }}
        />
      </p>
      <p>
        <button className="season-button" type="submit">
          Сгенерировать DataMatrix
        </button>
      </p>
    </form>
  );
};

export default Form;
