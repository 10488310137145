import { Fragment } from 'react';

const Header = () => {
  return (
    <Fragment>
      <div className="notforprint">
        <h1>Генератор DataMatrix</h1>
      </div>

      <div className="forprint">
        <h1>Сгенерировано на dm.m1w.ru</h1>
        <h3>https://dm.m1w.ru/</h3>
        <div className="header-line">.................................................</div>
      </div>
    </Fragment>
  );
};

export default Header;
