import { format } from 'date-fns';

import './Seasons.scss';

const Seasons = ({ children }) => {
  const seasons = {
    12: 'winter',
    1: 'winter',
    2: 'winter',
    3: 'spring',
    4: 'spring',
    5: 'spring',
    6: 'summer',
    7: 'summer',
    8: 'summer',
    9: 'autumn',
    10: 'autumn',
    11: 'autumn',
  };

  return <div className={seasons[format(new Date(), 'L')]}>{children}</div>;
};

export default Seasons;
