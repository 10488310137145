import Box from '../Box/Box';

const BoxList = ({ boxes }) => {
  const boxEls = boxes.map((v, i) => {
    return <Box content={v} key={i} id={i + 1} />;
  });

  return (
    <section>
      <div className="boxes">{boxEls}</div>
    </section>
  );
};

export default BoxList;
