import { format } from 'date-fns';

const Footer = () => {
  return (
    <div className="footer notforprint">
      <p>
        © 2015 – {format(new Date(), 'yyyy')} | <a href="https://m1w.ru">m1w.ru</a>
      </p>
    </div>
  );
};

export default Footer;
