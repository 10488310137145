import DataMatrix from 'react-datamatrix-svg';

const Box = ({ content, id }) => {
  return (
    <div className="box">
      <DataMatrix msg={content.replace(/\\x1d/gi, '\x1d')} dim={100} pal={['#000', '#fff']} />
      <div>{id}</div>
    </div>
  );
};

export default Box;
